import { useState, useEffect } from 'react';

interface PersistOptions<T> {
    validateFn?: (value: any) => boolean;
    onError?: (error: Error) => void;
}

const PERSISTENT_KEYS = 'persistent-keys';

export function usePersistedState<T>(
    key: string,
    initialValue: T,
    options: PersistOptions<T> = {}
): [T, (value: T | ((prev: T) => T)) => void] {
    // Initialize state with persisted data or initial value
    const [state, setState] = useState<T>(() => {
        try {
            const item = localStorage.getItem(key);
            if (item) {
                const parsed = JSON.parse(item);
                
                // If validation function is provided, use it
                if (options.validateFn && !options.validateFn(parsed)) {
                    return initialValue;
                }
                
                return parsed;
            }
        } catch (error) {
            if (options.onError) {
                options.onError(error as Error);
            } else {
                console.warn(`Error loading persisted state for key "${key}":`, error);
            }
        }
        return initialValue;
    });

    // Update localStorage when state changes
    useEffect(() => {
        try {
            setPersistedItem(key, state);
        } catch (error) {
            if (options.onError) {
                options.onError(error as Error);
            } else {
                console.warn(`Error saving persisted state for key "${key}":`, error);
            }
        }
    }, [key, state]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            try {
                setPersistedItem(key, state);
            } catch (error) {
                if (options.onError) {
                    options.onError(error as Error);
                }
            }
        };
    }, [key, state]);

    return [state, setState];
}

const setPersistedItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
    updatePersistentKeys(key);
};

const updatePersistentKeys = (key: string) => {
    const existingKeys = localStorage.getItem(PERSISTENT_KEYS);
    let keys = existingKeys ? JSON.parse(existingKeys) : [];
    
    if (!keys.includes(key)) {
        keys.push(key);
        localStorage.setItem(PERSISTENT_KEYS, JSON.stringify(keys));
    }
};

export function getPersistentStateKeys(): string[] | null {
    try {
        const item = localStorage.getItem(PERSISTENT_KEYS);
        if (item) {
            return JSON.parse(item);
        }
    } catch (error) {
        console.warn(`Error getting persisted state keys:`, error);
    }
    return null;
}


export const getPersistedState = (key: string) => {
    const item = localStorage.getItem(key);
    if (item) {
        return JSON.parse(item);
    }
    return null;
}