// src/types/entity.ts
export interface ArchetypeStats {
    archetype: string;
    count: number;
    percentage: number;
}

export interface ProfileStats {
    profile: string;
    count: number;
    percentage: number;
}

export interface EntityStatistics {
    entityId: string;
    lastActivityDate: string | null;
    uniqueUsers: number;
    numberOfAssessments: number;
    numberOfPositions: number;
    numberOfFolders: number;
    numberOfTeams: number;
    archetypeStats: ArchetypeStats[];
    profileStats: ProfileStats[];
    totalArchetypeCount: number;
    totalProfileCount: number;
}

export interface Entity {
    _id: string;
    name: string;
    lastActivityDate: string | null;
    numberOfAssessments: number;
    numberOfPositions: number;
    numberOfFolders: number;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    contactNumber: string;
    address: {
        addressLine1: string,
        addressLine2: string,
        cityTown: string,
        stateProvinceRegion: string,
        postalCode: string,
        country: string,
    };
    contractStartedDate: Date | null;
    lastRenewalDate: Date | null;
    expiryDate: Date | null;
    lastActivity: Date | null;
    annualPrice: {
        billingFrequency: string,
        invoiceAmount: number,
    };
    employeeHeadCount: string;
    createdAt: string;
    updatedAt: string;
    status: string;
    notes: string;
    isActive: boolean;
    advisors: any[];
    kind: string;
    stats?: EntityStatistics;
    userAccessibility: boolean;
    tags: string[];
    productTier?: string;
}
export enum PRODUCT_TIER {
    STARTER = 'starter',
    GROWTH = 'growth',
    PROFESSIONAL = 'professional',
    ENTERPRISE = 'enterprise'
}
