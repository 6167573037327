import React, { FC, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { SquaresFour, UsersThree, FolderOpen, Briefcase, UserGear, Atom, SignOut, TreeStructure, Sun, Moon, Desktop, Gear } from "@phosphor-icons/react";
import { PRODUCT_TIER } from '../types/entity';

interface Props {
    setIsMenuOpen?: (menuOpen: boolean) => void;
    handleLogout: () => void;
    darkMode: 'light' | 'dark' | 'system';
    setDarkMode: (mode: 'light' | 'dark' | 'system') => void;
    handleProfileClick?: () => void;
}

const NavigationLinks: FC<Props> = ({ 
    setIsMenuOpen, 
    handleLogout, 
    darkMode, 
    setDarkMode, 
}) => {
    const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);
    const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const userDetail = JSON.parse(localStorage.getItem('user') || "{}");
    const location = useLocation();

    //GOD_MODE_USER_ID
    const IS_GOD_MODE_USER: boolean = userDetail.user.isGodModeUser;
    const IS_ADVISOR: boolean = userDetail ? userDetail.user.isAdvisor : false;

    let entityMenuName = "";
    if (IS_GOD_MODE_USER) {
        entityMenuName = "God Mode";
    } else if (IS_ADVISOR) {
        entityMenuName = "Clients";
    } 

    const handleThemeToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsThemeMenuOpen(!isThemeMenuOpen);
        setIsSettingsMenuOpen(false);
    };

    const handleSettingsToggle = (e: React.MouseEvent) => {
        e.preventDefault();
        setIsSettingsMenuOpen(!isSettingsMenuOpen);
        setIsThemeMenuOpen(false);
    };

    const handleSettingsClick = (action: 'profile' | 'orgSettings') => {
        if (action === 'profile') {
            navigate('/profile');
        } else {
            navigate('/settings');
        }
        setIsSettingsMenuOpen(false);
        setIsMenuOpen && setIsMenuOpen(false);
    };

    const handleThemeChange = (mode: 'light' | 'dark' | 'system') => {
        setDarkMode(mode);
        setIsThemeMenuOpen(false);
        setIsMenuOpen && setIsMenuOpen(false);
    };

    return (
        <div className="sidebar-navigation-wrapper">
            <ul className="sidebar-navigation">
                <li>
                    <NavLink
                        onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                        to="/dashboard"
                        className={({ isActive }) => (isActive || location.pathname === '/' ? 'active' : '')}
                    >
                        {({ isActive }) => (
                            <>
                                <SquaresFour size={24} className={isActive || location.pathname === '/' ? 'active' : ''} />
                                <span>Dashboard</span>
                            </>
                        )}
                    </NavLink>
                </li>
                {(userDetail?.user?.permissions?.people || userDetail?.user?.role === "Super Admin") &&
                    <li>
                        <NavLink
                            onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                            to="/people"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            {({ isActive }) => (
                                <>
                                    <UsersThree size={24} className={isActive ? 'active' : ''} />
                                    <span>People</span>
                                </>
                            )}
                        </NavLink>
                    </li>}
                {(userDetail?.user?.permissions?.positionsTargets || userDetail?.user?.role === "Super Admin") &&
                    <li>
                        <NavLink
                            onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                            to="/positions"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            {({ isActive }) => (
                                <>
                                    <Briefcase size={24} className={isActive ? 'active' : ''} />
                                    <span>Positions</span>
                                </>
                            )}
                        </NavLink>
                    </li>}
                {(userDetail?.user?.permissions?.folders || userDetail?.user?.role === "Super Admin") &&
                    <li>
                        <NavLink
                            onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                            to="/folders"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            {({ isActive }) => (
                                <>
                                    <FolderOpen size={24} className={isActive ? 'active' : ''} />
                                    <span>Folders</span>
                                </>
                            )}
                        </NavLink>
                    </li>}

                {userDetail?.user?.permissions?.productTier && userDetail?.user?.permissions?.productTier !== PRODUCT_TIER.STARTER && (userDetail?.user?.permissions?.teams || userDetail.user.role === "Super Admin") &&
                    <li>
                        <NavLink
                            onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                            to="/teams"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            {({ isActive }) => (
                                <>
                                    <TreeStructure size={24} className={isActive ? 'active' : ''} />
                                    <span>Teams</span>
                                </>
                            )}
                        </NavLink>
                    </li>
                }

                <li className={`theme-menu hide-on-desktop ${isThemeMenuOpen ? 'open' : ''}`}>
                    <a href="/#" onClick={handleThemeToggle}>
                        {darkMode === 'dark' ? <Moon className="nav-icon" /> :
                            darkMode === 'light' ? <Sun className="nav-icon" /> :
                                <Desktop className="nav-icon" />}
                        <span>Theme</span>
                    </a>
                    <ul className="theme-submenu">
                        <li>
                            <button onClick={() => handleThemeChange('light')}>
                                <Sun size={16} /> Light
                            </button>
                        </li>
                        <li>
                            <button onClick={() => handleThemeChange('dark')}>
                                <Moon size={16} /> Dark
                            </button>
                        </li>
                        <li>
                            <button onClick={() => handleThemeChange('system')}>
                                <Desktop size={16} /> System
                            </button>
                        </li>
                    </ul>
                </li>

                <li className={`theme-menu hide-on-desktop ${isSettingsMenuOpen ? 'open' : ''}`}>
                    <a href="/#" onClick={handleSettingsToggle}>
                        <Gear className="nav-icon" />
                        <span>Settings</span>
                    </a>
                    <ul className="theme-submenu">
                        <li>
                            <button onClick={() => handleSettingsClick('profile')}>
                                My Profile
                            </button>
                        </li>
                        {(userDetail?.user?.isGodModeUser || userDetail?.user?.role === "Super Admin") &&
                            <li>
                                <button onClick={() => handleSettingsClick('orgSettings')}>
                                    Org Settings
                                </button>
                            </li>
                        }
                    </ul>
                </li>

                {(userDetail?.user?.permissions?.users || userDetail?.user?.role === "Super Admin") &&
                    <li>
                        <NavLink
                            onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                            to="/users"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            {({ isActive }) => (
                                <>
                                    <UserGear size={24} className={isActive ? 'active' : ''} />
                                    <span>Users</span>
                                </>
                            )}
                        </NavLink>
                    </li>}
                {(IS_GOD_MODE_USER || IS_ADVISOR) &&
                    <li>
                        <NavLink
                            onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
                            to="/entities"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            {({ isActive }) => (
                                <>
                                    <Atom size={24} className={isActive ? 'active' : ''} />
                                    <span>{entityMenuName}</span>
                                </>
                            )}
                        </NavLink>
                    </li>
                }

                <li className="mobile-logout">
                    <a href="/login" onClick={handleLogout}>
                        <SignOut size={24} />
                        <span>Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default NavigationLinks;
