import React from 'react';
import { ReactComponent as ChallengerIcon } from './profile-svg-challenger.svg';
import { ReactComponent as ExplorerIcon } from './profile-svg-explorer.svg';
import { ReactComponent as TrailblazerIcon } from './profile-svg-trailblazer.svg';
import { ReactComponent as EnterpriserIcon } from './profile-svg-enterpriser.svg';
import { ReactComponent as ArchitectIcon } from './profile-svg-architect.svg';
import { ReactComponent as PersuaderIcon } from './profile-svg-persuader.svg';
import { ReactComponent as RainmakerIcon } from './profile-svg-rainmaker.svg';
import { ReactComponent as MaverickIcon } from './profile-svg-maverick.svg';
import { ReactComponent as CatalystIcon } from './profile-svg-catalyst.svg';
import { ReactComponent as ConnectorIcon } from './profile-svg-connector.svg';
import { ReactComponent as ExpertIcon } from './profile-svg-expert.svg';
import { ReactComponent as SageIcon } from './profile-svg-sage.svg';
import { ReactComponent as MavenIcon } from './profile-svg-maven.svg';
import { ReactComponent as ArtisanIcon } from './profile-svg-artisan.svg';
import { ReactComponent as AdministratorIcon } from './profile-svg-administrator.svg';
import { ReactComponent as FacilitatorIcon } from './profile-svg-facilitator.svg';
import { ReactComponent as CoordinatorIcon } from './profile-svg-coordinator.svg';
import { ReactComponent as PerfecterIcon } from './profile-svg-perfecter.svg';
import { ReactComponent as SentinelIcon } from './profile-svg-sentinel.svg';
import { ReactComponent as AdapterIcon } from './profile-svg-adapter.svg';
import { ReactComponent as NotSetIcon } from './profile-svg-notset.svg';

const profileIcons: { [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    challenger: ChallengerIcon,
    explorer: ExplorerIcon,
    trailblazer: TrailblazerIcon,
    enterpriser: EnterpriserIcon,
    architect: ArchitectIcon,
    persuader: PersuaderIcon,
    rainmaker: RainmakerIcon,
    maverick: MaverickIcon,
    catalyst: CatalystIcon,
    connector: ConnectorIcon,
    expert: ExpertIcon,
    sage: SageIcon,
    maven: MavenIcon,
    artisan: ArtisanIcon,
    administrator: AdministratorIcon,
    facilitator: FacilitatorIcon,
    coordinator: CoordinatorIcon,
    perfecter: PerfecterIcon,
    sentinel: SentinelIcon,
    adapter: AdapterIcon,
    notset: NotSetIcon,
};

interface ProfileIconProps {
    profileName: string;
    className?: string;
    x?: number;
    y?: number;
    width?: number | string;
    height?: number | string;
    onMouseEnter?: React.MouseEventHandler<SVGSVGElement>;
    onMouseLeave?: React.MouseEventHandler<SVGSVGElement>;
}

export const ProfileIcon: React.FC<ProfileIconProps> = ({
    profileName,
    className,
    x,
    y,
    width,
    height,
    onMouseEnter,
    onMouseLeave
}) => {
    const IconComponent = profileIcons[profileName?.toLowerCase()];

    if (!IconComponent) {
        console.warn(`No icon found for profile: ${profileName}`);
        return null;
    }

    const svgProps: React.SVGProps<SVGSVGElement> = {
        className,
        style: {},
        onMouseEnter,
        onMouseLeave
    };

    if (x !== undefined && y !== undefined) {
        svgProps.x = x;
        svgProps.y = y;
    } else {
        svgProps.style = { ...svgProps.style, transform: `translate(${x}px, ${y}px)` };
    }

    if (width !== undefined) svgProps.width = width;
    if (height !== undefined) svgProps.height = height;

    return <IconComponent {...svgProps} />;
};