import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import UniversalSearchAPI, { SearchResult } from '../services/universalSearch';
import { MagnifyingGlass, Briefcase, FolderOpen, User } from "@phosphor-icons/react";

export interface UniversalSearchBarProps {
    onFocus?: () => void;
    onBlur?: () => void;
    className?: string;
    onResultSelect: (result: SearchResult | null) => void;
    placeholder?: string;
    isMobile?: boolean;
    debounceTime?: number;
}

export interface UniversalSearchBarRef {
    focus: () => void;
}

const UniversalSearchBar = forwardRef<UniversalSearchBarRef, UniversalSearchBarProps>(({
    onFocus,
    onBlur,
    className,
    onResultSelect,
    placeholder = "Search...",
    debounceTime = 300,
}, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<SearchResult[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);
    const touchStartYRef = useRef<number | null>(null);
    const touchStartXRef = useRef<number | null>(null);
    const touchStartTimeRef = useRef<number | null>(null);
    
    // Constants for touch detection
    const TOUCH_MOVE_THRESHOLD = 10; // pixels
    const TOUCH_TIME_THRESHOLD = 300; // milliseconds

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        },
    }));

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current && 
            !dropdownRef.current.contains(event.target as Node) &&
            inputRef.current && 
            !inputRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false);
            setIsFocused(false);
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            event.preventDefault();
            inputRef.current?.focus();
        }
    };

    const fetchSearchResults = useCallback(async (query: string) => {
        if (query.trim() === '') {
            setOptions([]);
            return;
        }

        setIsLoading(true);
        try {
            const results = await UniversalSearchAPI.search(query);
            setOptions(results);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setOptions([]);
        } finally {
            setIsLoading(false);
        }
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);
        setIsOpen(true);

        // Clear any existing debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        // Set a new debounce timer
        debounceTimerRef.current = setTimeout(() => {
            fetchSearchResults(value);
        }, debounceTime);
    };

    const handleSelectResult = (result: SearchResult) => {
        onResultSelect(result);
        setInputValue('');
        setOptions([]);
        setIsOpen(false);
        setIsFocused(false);
        setHighlightedIndex(-1);
        inputRef.current?.blur();
    };

    const handleOptionClick = (e: React.MouseEvent, result: SearchResult) => {
        e.preventDefault();
        e.stopPropagation();
        handleSelectResult(result);
    };

    const handleTouchStart = (e: React.TouchEvent, result: SearchResult, index: number) => {
        // Store touch start position and time
        if (e.touches && e.touches[0]) {
            touchStartYRef.current = e.touches[0].clientY;
            touchStartXRef.current = e.touches[0].clientX;
            touchStartTimeRef.current = Date.now();
        }
        // Highlight the item being touched
        setHighlightedIndex(index);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        // When user is scrolling, we want to allow the default behavior
        if (touchStartYRef.current === null) return;
        
        // Calculate movement distance
        const touchY = e.touches[0].clientY;
        const deltaY = Math.abs(touchY - touchStartYRef.current);
        
        // If scrolling vertically, reset touch tracking and allow default scroll behavior
        if (deltaY > TOUCH_MOVE_THRESHOLD) {
            touchStartYRef.current = null;
            touchStartXRef.current = null;
            touchStartTimeRef.current = null;
            // Reset the highlighted index when scrolling
            setHighlightedIndex(-1);
        }
    };

    const handleTouchEnd = (e: React.TouchEvent, result: SearchResult) => {
        if (
            touchStartYRef.current !== null &&
            touchStartXRef.current !== null &&
            touchStartTimeRef.current !== null
        ) {
            const touchEndTime = Date.now();
            const touchDuration = touchEndTime - touchStartTimeRef.current;
            
            // If the touch was short (tap) and didn't move much, treat as a selection
            if (touchDuration < TOUCH_TIME_THRESHOLD) {
                e.preventDefault();
                e.stopPropagation();
                handleSelectResult(result);
            }
        }
        
        // Reset touch tracking
        touchStartYRef.current = null;
        touchStartXRef.current = null;
        touchStartTimeRef.current = null;
    };

    const handleInputFocus = () => {
        setIsFocused(true);
        setIsOpen(true);
        onFocus?.();
    };

    const handleInputBlur = () => {
        onBlur?.();
    };

    const handleKeyDownInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => (prevIndex + 1) % options.length);
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex((prevIndex) => (prevIndex - 1 + options.length) % options.length);
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (highlightedIndex >= 0 && highlightedIndex < options.length) {
                handleSelectResult(options[highlightedIndex]);
            }
        } else if (e.key === 'Escape') {
            e.preventDefault();
            setInputValue('');
            setOptions([]);
            setIsOpen(false);
            setHighlightedIndex(-1);
            setIsFocused(false);
            inputRef.current?.blur();
        }
    };

    const renderResultIcon = (type: string) => {
        switch (type) {
            case 'user':
                return <User className="result-icon" />;
            case 'target':
                return <Briefcase className="result-icon" />;
            case 'group':
                return <FolderOpen className="result-icon" />;
            default:
                return null;
        }
    };

    return (
        <div className={`search-bar ${className || ''} ${isFocused ? 'focused' : ''}`}>
            <div className="search-bar-input-wrapper">
                <input
                    ref={inputRef}
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDownInput}
                    placeholder={placeholder}
                    className="search-bar-input"
                />
                <MagnifyingGlass className="search-bar-icon" />
            </div>
            {isOpen && (
                <div 
                    ref={dropdownRef} 
                    className="search-bar-dropdown"
                >
                    {isLoading ? (
                        <div className="search-bar-no-options">
                            Searching...
                        </div>
                    ) : options.length > 0 ? (
                        options.map((option, index) => (
                            <div
                                key={option.id}
                                onClick={(e) => handleOptionClick(e, option)}
                                onTouchStart={(e) => handleTouchStart(e, option, index)}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={(e) => handleTouchEnd(e, option)}
                                className={`search-bar-option ${highlightedIndex === index ? 'highlighted' : ''}`}
                            >
                                {renderResultIcon(option.type)}
                                <span className="result-text">{option.name} {option.email && ` - ${option.email}`}</span>
                            </div>
                        ))
                    ) : (
                        <div className="search-bar-no-options">
                            {inputValue.trim() === '' ? 'Begin typing to search...' : 'No results found...'}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
});

export default UniversalSearchBar;