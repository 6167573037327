export enum ROLE {
    SUPER_ADMIN = "Super Admin",
    ADMINISTRATOR = "Administrator",
    STANDARD_USER = "Standard User",
}

export enum SHARED_TYPE {
    POSITION = "POSITION",
    GROUP = "GROUP",
    TEAM = "TEAM"
}

export enum POSITION_STATUS {
    INTERNAL = "internal",
    ACTIVE = "active",
}

export const canCopyLinks = () => {
    const userDetail = JSON.parse(localStorage.getItem('user') || "{}");
    // Super Admin can always copy links
    if (userDetail?.user?.role === "Super Admin") {
        return true;
    }
    // Only block if copyLinks is explicitly set to false
    if (userDetail?.user?.permissions?.copyLinks === false) {
        return false;
    }
    // Allow by default
    return true;
}

export const canChangeOwnPassword = () => {
    const userDetail = JSON.parse(localStorage.getItem('user') || "{}");
    // Super Admin can always change password
    if (userDetail?.user?.role === "Super Admin") {
        return true;
    }
    // Only block if changeOwnPassword is explicitly set to false
    if (userDetail?.user?.permissions?.changeOwnPassword === false) {
        return false;
    }
    // Allow by default
    return true;
}

export const isOwnProfile = () => {
    const userDetail = JSON.parse(localStorage.getItem('user') || "{}");
    if (userDetail?.user?.permissions?.viewOwnProfileOnly && userDetail?.user?.role === "Standard User") {
        return true;
    }
    return false;
}

export const enum PEOPLE_DROPDOWN {
    SHARED = 'Shared',
    OWN = "Own",
    ALL = 'All',
    RECENT = 'Recent Matches'
}